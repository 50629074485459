<script>
  import FooterIcon from "../icons/FooterIcon.svelte";
  import ErrorIcon from "../icons/ErrorIcon.svelte";

  export let isRedirectModalVisible;
  export let onCloseModal;
  export let redirectionLink;
</script>

<div>
  <div class="modal {isRedirectModalVisible ? 'modal--visible' : ''}">
    <div class="modal__content">
      <button on:click={onCloseModal} class="modal__close-icon">X</button>
      {#if isRedirectModalVisible}
        <div class="modal__body">
          <div class="modal__title">Complete the payment</div>
          <div class="modal__message">
            You're going to be redirected to esRamp to complete the payment.
            <br /><br />
            Do you want to proceed?
          </div>
          <div class="row">
            <button
              on:click={() => (location.href = redirectionLink)}
              class="modal__btn">Yes</button
            >
            <button on:click={onCloseModal} class="modal__btn">No</button>
          </div>
        </div>
      {/if}
      <div class="modal__footer">
        <span>Powered by</span>
        <div class="footer-svg">
          <FooterIcon />
        </div>
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  @use "../../styles/modal.styles.scss";
</style>
